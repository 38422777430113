import PropTypes from 'prop-types'
import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'

import s from './ErrorView.scss'

class ErrorView extends React.PureComponent {
  static propTypes = {
    error: PropTypes.shape({
      name: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      stack: PropTypes.string.isRequired,
    }),
  }

  static defaultProps = {
    error: null,
  }

  render() {
    if (__DEV__ && this.props.error) {
      return (
        <div>
          <h1>{this.props.error.name}</h1>
          <pre>{this.props.error.stack}</pre>
        </div>
      )
    }

    return (
      <div>
        <h1>Error</h1>
        <p>Sorry, a critical error occurred on this page.</p>
      </div>
    )
  }
}

export { ErrorView as ErrorPageWithoutStyle }
export default withStyles(s)(ErrorView)
