// import { t } from 'acs-translate'
import Logger from 'utils/Logger'

import { setSystemError } from './modal'

const defaultResultTransform = data => data

const createGetAction = ({
  // name,
  action,
  // variables = {},
  onComplete,
  onError,
  onErrorConfirm,
  transform = defaultResultTransform,
  defaultValue = {},
  useCustomErrorMessage = false,
  defaultErrorMessageCd = null,
  shouldThrowError = false, //
  // lazy = false,
}) => async dispatch => {
  try {
    const rslt = await dispatch(action)

    // eslint-disable-next-line no-unused-expressions
    await onComplete?.(transform(rslt))
    return rslt
  } catch (err) {
    // eslint-disable-next-line no-unused-expressions
    const isHandled = onError?.(err)

    // error is handled in custom callback
    if (isHandled || err?.isHandled) {
      return defaultValue
    }
    Logger.error(err)

    if (defaultErrorMessageCd) {
      const errorCd = err?.code || err?.response?.status

      const getErrorMessage = () => {
        // if (!errorCd) return t(defaultErrorMessageCd)

        // return `${t(defaultErrorMessageCd)}(${errorCd})`

        if (!errorCd) return 'defaultErrorMessageCd'

        return `defaultErrorMessageCd(${errorCd})`
      }
      const getErrorCodeOrContent = () => {
        if (parseInt(errorCd, 10)) {
          return {
            code: parseInt(errorCd, 10),
          }
        }

        return {
          message: getErrorMessage(),
        }
      }

      dispatch(
        setSystemError({
          ...(useCustomErrorMessage
            ? {
                message: getErrorMessage(),
              }
            : {}),
          ...getErrorCodeOrContent(),
          fallback: getErrorMessage(),
          callback: () => onErrorConfirm?.(err),
        }),
      )
    }

    if (shouldThrowError) {
      throw err
    }
  }

  return defaultValue
}

export default createGetAction
